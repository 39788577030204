import React from 'react';
import styled from 'styled-components';
import { bodyS, h6 } from '../../styles/typography';

const Host = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const Figure = styled.div<{ isGrayBg?: boolean }>`
  max-width: 200px;
  margin-bottom: ${(props) => props.theme.gutter}px;
`;

const Title = styled.h2`
  margin: 0;
  ${h6};
  font-weight: 600;
  text-align: center;
  margin-bottom: ${(props) => props.theme.gutter}px;
`;

const Copy = styled.p`
  margin: 0;
  ${bodyS}
  text-align: center;
  max-width: 320px;
`;

interface FeatureItemProps {
  image?: React.ReactNode;
  title: string;
  copy: string;
  isGrayBg?: boolean;
}

export const FeatureItem = ({
  image,
  title,
  copy,
  isGrayBg,
}: FeatureItemProps) => {
  return (
    <Host>
      <Figure isGrayBg={isGrayBg}>{image}</Figure>
      <Title>{title}</Title>
      <Copy>{copy}</Copy>
    </Host>
  );
};
