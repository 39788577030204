import React from 'react';
import styled from 'styled-components';
import { bodyM, h6 } from '../../styles/typography';

const Host = styled.article`
  flex: 1;
  padding: 36px;
  max-width: 350px;
  background: ${({ theme }) =>
    theme.colorScheme === 'dark' ? theme.colors.gray[8] : '#fff'};
`;

const Figure = styled.div`
  width: 101px;
  margin-bottom: ${(props) => props.theme.gutter}px;
`;

const Title = styled.h2`
  margin: 0;
  ${h6};
  font-weight: 600;
  margin-bottom: ${(props) => props.theme.gutter / 2}px;
`;

const Spacer = styled.div`
  height: 1px;
  width: 32px;

  background-color: #000;
  margin-bottom: ${(props) => props.theme.gutter / 2}px;
`;

const Copy = styled.p`
  margin: 0;
  ${bodyM};
  margin-bottom: ${(props) => props.theme.gutter}px;
`;

interface StepCardProps {
  image: React.ReactNode;
  title: string;
  copy: string;
}

export const StepCard = ({ image, title, copy }: StepCardProps) => {
  return (
    <Host>
      <Figure>{image}</Figure>
      <Title>{title}</Title>
      <Spacer />
      <Copy>{copy}</Copy>
    </Host>
  );
};
