import truncate from 'lodash/truncate';
import Head from 'next/head';
const DEFAULT_TITLE = 'Mintum breytir krónum í rafkrónur';
const DEFAULT_DESCRIPTION =
  'Mintum.is er í eigu Rafmyntasjóðs Íslands sem er skráður þjónustuveitandi sýndarfjárs hjá Seðlabanka Íslands';

export const MetaContainer = ({
  title,
  description = DEFAULT_DESCRIPTION,
}: {
  title?: string;
  description?: string;
}) => {
  const truncatedDescription = truncate(description.replace(/\n+/g, ' '), {
    length: 300,
    separator: '. ',
  });

  return (
    <Head>
      <title>{title ? `${title} | Mintum` : DEFAULT_TITLE}</title>
      <meta name="description" content={truncatedDescription} />
      <meta property="og:description" content={truncatedDescription} />
      <meta name="twitter:description" content={truncatedDescription} />
      <meta property="og:image" content="https://beta.mintum.is/og.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content="Mintum logo" />
      <meta property="twitter:image" content="https://beta.mintum.is/og.png" />
    </Head>
  );
};
