import styled from 'styled-components';

export const InlineLink = styled.a`
  color: ${(props) => props.theme.colors.blue[3]};
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.colors.blue[5]};
    text-decoration: underline;
  }
  &:active {
    color: ${(props) => props.theme.colors.blue[6]};
  }
  &:focus {
    color: ${(props) => props.theme.colors.blue[7]};
  }
  &:visited {
    color: ${(props) => props.theme.colors.blue[8]};
  }
`;
