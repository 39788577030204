import styled from 'styled-components';
import { bodyXs } from '../../styles/typography';

interface StepLinkItemProps {
  href: string;
  name: string;
  imageUrl: string;
}

const Host = styled.a`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: #000;

  &:hover {
    text-decoration: underline;
    color: #000;
  }
`;

const Figure = styled.img`
  width: 100%;
  margin-bottom: 4px;
  border-radius: 0.75rem;
  min-height: 60px;
`;

const Title = styled.h2`
  margin: 0;
  ${bodyXs};
  font-weight: 600;
  text-align: center;
  margin-top: auto;
  margin-bottom: ${(props) => props.theme.gutter}px;
`;

export const StepLinkItem = ({ href, name, imageUrl }: StepLinkItemProps) => {
  return (
    <Host href={href} target="_blank">
      <Figure src={imageUrl} alt={name} />
      <Title>{name}</Title>
    </Host>
  );
};
