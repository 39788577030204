import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/devices';

const Host = styled.div`
  display: flex;
  flex-flow: column nowrap;

  > * {
    margin: 32px 0;
  }

  > div:first-child > div:after {
    display: none;
  }

  @media ${device.tablet} {
    > * {
      margin: 90px 0;
    }
  }
`;

interface StepsProps {
  children?: React.ReactNode;
}

export const Steps = ({ children }: StepsProps) => {
  return <Host>{children}</Host>;
};
