import { Accordion } from '@mantine/core';
import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/devices';
import { bodyM } from '../../styles/typography';

const Host = styled.div`
  ${bodyM};

  @media ${device.tablet} {
    padding-right: 80px;
  }

  @media ${device.desktop} {
    padding-right: 180px;
  }
`;

interface AccordionContentProps {
  children: React.ReactNode;
}

export const AccordionContent = ({ children }: AccordionContentProps) => {
  return (
    <Accordion.Panel>
      <Host>{children}</Host>
    </Accordion.Panel>
  );
};
