import React from 'react';
import styled from 'styled-components';
import { bodyS } from '../../styles/typography';

const Host = styled.a`
  ${bodyS}
  display: flex;
  align-items: center;

  color: ${({ theme }) =>
    theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[9]};

  cursor: pointer;
  text-decoration: none;

  &:hover {
    span:first-child {
      opacity: 0.8;
      transition: opacity 200ms ease-in-out;
    }

    span:last-child {
      transform: translateX(5px);
      transition: transform 200ms ease-in-out;
    }
  }

  &:visited {
  }
`;

const Text = styled.span`
  opacity: 1;
  transition: opacity 200ms ease-in-out;
`;

const Icon = styled.span`
  margin-left: 12px;

  transform: translateX(0);
  transition: transform 200ms ease-in-out;
`;

interface CardLinkProps {
  children: React.ReactNode;
  target?: string;
  rel?: string;
  [key: string]: any;
}

export const CardLink = ({ children, target, rel, ...rest }: CardLinkProps) => {
  return (
    <Host {...rest} target={target} rel={rel}>
      <Text>{children}</Text>
      <Icon>
        <svg
          width="16"
          height="12"
          viewBox="0 0 16 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.87793 0.699219L14.8779 5.69922L9.87793 10.6992"
            stroke="currentColor"
          />
          <path d="M14.8779 5.69922H0.87793" stroke="currentColor" />
        </svg>
      </Icon>
    </Host>
  );
};
