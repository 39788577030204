import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/devices';
import { body } from '../../styles/typography';

const Host = styled(motion.a)`
  display: inline-flex;
  padding: 23px 30px;
  background-color: #003cff;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 300ms ease-in;

  &:hover {
    background-color: #0034de;
    transition: background-color 300ms ease-out;
  }
`;

const Content = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  ${body}
  font-weight: 600;
`;

const Text = styled.span``;

const Icon = styled(motion.span)`
  transform: translateX(-8px);
  margin-left: 20px;
  @media ${device.mobile} {
    margin-left: 94px;
  }
`;

interface HeroButtonProps {
  children: React.ReactNode;
  onClick?(e: React.MouseEvent<HTMLAnchorElement>): void;
}

export const HeroButton = ({ children, ...rest }: HeroButtonProps) => {
  const arrowMotion = {
    rest: {
      x: -8,
      transition: {
        duration: 0.3,
        type: 'tween',
        ease: 'easeIn',
      },
    },
    hover: {
      x: 0,
      transition: {
        duration: 0.3,
        type: 'tween',
        ease: 'easeOut',
      },
    },
  };

  return (
    <Host
      initial="rest"
      whileHover="hover"
      whileTap="hover"
      animate="rest"
      {...rest}>
      <Content>
        <Text>{children}</Text>
        <Icon variants={arrowMotion}>
          <svg
            width="25"
            height="12"
            viewBox="0 0 25 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M18 1L23 6L18 11" stroke="#FEFEFE" strokeWidth="2" />
            <path d="M23 6H0" stroke="#FEFEFE" strokeWidth="2" />
          </svg>
        </Icon>
      </Content>
    </Host>
  );
};
