import { Container } from '@mantine/core';
import styled from 'styled-components';

const Line = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) =>
    theme.colorScheme === 'dark'
      ? 'rgba(255,255,255,0.2)'
      : 'rgba(0, 0, 1, 0.1)'};
`;

export const Divider = () => {
  return (
    <Container size="xl" p="xl" sx={{ width: '100%' }}>
      <Line />
    </Container>
  );
};
