import { Container } from '@mantine/core';
import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/devices';
import { linkColor } from '../../styles/mixins';
import { bodyM, h1, h3 } from '../../styles/typography';
import { LazyShow } from '../reveal/reveal';

const Host = styled.article<{ isGray?: boolean }>`
  padding: ${(props) => props.theme.segmentPaddingMobile}px 0;
  background-color: ${(props) =>
    props.isGray ? props.theme.colors.gray[0] : 'transparent'};

  @media ${device.tablet} {
    padding-top: ${(props) =>
      (props.theme.segmentPadding / props.theme.pageLimit(props)) * 100}%;
    padding-bottom: ${(props) =>
      (props.theme.segmentPadding / props.theme.pageLimit(props)) * 100}%;
  }

  @media ${device.pageLimit} {
    padding-top: ${(props) => props.theme.segmentPadding}px;
    padding-bottom: ${(props) => props.theme.segmentPadding}px;
  }
`;

const Flex = styled.div<{ isReverse?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isReverse ? 'column-reverse' : 'column')};
`;

const Header = styled.header`
  margin: 0 auto;
  text-align: center;
  margin-bottom: ${(props) => props.theme.gutter}px;

  @media ${device.tablet} {
    max-width: 73%;
    margin-bottom: ${(props) => props.theme.gutter * 1.5}px;
  }

  @media ${device.desktop} {
    margin-bottom: ${(props) => props.theme.gutter * 3}px;
  }
`;

const Title = styled.h2<{ isSmaller?: boolean }>`
  margin: 0;
  ${(props) => (props.isSmaller ? h3 : h1)}
  margin-bottom: ${(props) => props.theme.gutter}px;
`;

const Description = styled.p`
  margin: 0;
  ${bodyM}

  a {
    ${linkColor}
  }
`;

const Childs = styled.div<{ hasBottomSpace?: boolean }>`
  ${(props) => props.hasBottomSpace && 'margin-bottom: 60px'}
`;

const Action = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.gutter}px;

  @media ${device.tablet} {
    margin-top: ${(props) => props.theme.gutter * 2}px;
  }

  @media ${device.desktop} {
    margin-top: ${(props) => props.theme.gutter * 3}px;
  }
`;

interface SegmentProps {
  title?: string;
  description?: string | React.ReactNode;
  children: React.ReactNode;
  isGray?: boolean;
  isSmallerTitle?: boolean;
  isReverse?: boolean;
  action?: React.ReactNode;
}

export const Segment = ({
  title,
  description,
  children,
  isGray,
  isSmallerTitle,
  isReverse,
  action,
}: SegmentProps) => {
  const hasHeader = title || description;
  return (
    <Host isGray={isGray}>
      <Container size="xl" p="xl" sx={{ width: '100%' }}>
        <Flex isReverse={isReverse}>
          {hasHeader && (
            <LazyShow>
              <Header>
                {title && <Title isSmaller={isSmallerTitle}>{title}</Title>}
                {description && <Description>{description}</Description>}
              </Header>
            </LazyShow>
          )}
          <LazyShow>
            <Childs hasBottomSpace={isReverse}>{children}</Childs>
            {action && <Action>{action}</Action>}
          </LazyShow>
        </Flex>
      </Container>
    </Host>
  );
};
