import { Accordion } from '@mantine/core';
import styled from 'styled-components';
import { h7 } from '../../styles/typography';

const Host = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const Title = styled.p`
  ${h7};
  font-weight: 500;
`;

interface AccordionLabelProps {
  title: string;
}

export const AccordionLabel = ({ title }: AccordionLabelProps) => {
  return (
    <Accordion.Control>
      <Host>
        <Title>{title}</Title>
      </Host>
    </Accordion.Control>
  );
};
