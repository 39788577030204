import { motion } from 'framer-motion';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { device } from '../../styles/devices';
import { bodyM, bodyS } from '../../styles/typography';

const Wrapper = styled.div<{ isSmall?: boolean }>`
  position: relative;
  display: inline-flex;

  min-width: 100%;
  @media ${device.mobile} {
    min-width: ${({ isSmall }) => (isSmall ? '240px' : '320px')};
  }
`;

const Bg = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 2px);
  height: calc(100% + 2px);

  background: linear-gradient(90deg, #000 0%, #000 100%);
  background-size: 600% 600%;
  z-index: -1;
  transform: translate(-1px, -1px);
`;

const AnimateBorder = keyframes`
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
`;

const Host = styled(motion.a)<{
  isWhite?: boolean;
  bgColor?: string;
  isSmall?: boolean;
}>`
  display: inline-flex;
  padding: ${({ isSmall }) => (isSmall ? '15px 20px' : '21px 30px')};
  width: 100%;

  cursor: pointer;
  justify-content: center;
  text-decoration: none;

  background-color: ${({ bgColor, theme }) =>
    bgColor
      ? bgColor
      : theme.colorScheme === 'dark'
      ? theme.colors.gray[8]
      : theme.colors.gray[0]};

  &:hover > ${Bg} {
    background: linear-gradient(
      90deg,
      #ffae01 0%,
      #f01464 30.43%,
      #0055ee 68.23%,
      #ce3eff 100%
    );
    background-size: 600% 600%;
    animation: ${AnimateBorder} 4s ease infinite;
  }
`;

const Content = styled.span<{ isWhite?: boolean; isSmall?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  ${({ isSmall }) => (isSmall ? bodyS : bodyM)};
  font-weight: 500;

  color: ${({ theme, isWhite }) =>
    !!isWhite
      ? theme.colors.gray[8]
      : theme.colorScheme === 'dark'
      ? theme.colors.gray[0]
      : theme.colors.gray[8]};
`;

const Text = styled.span<{ isCentered?: boolean }>`
  // min-width: ${(props) => (props.isCentered ? '260px' : 'inherit')};
  text-align: ${(props) => (props.isCentered ? 'center' : 'inherit')};
`;

const Icon = styled(motion.span)`
  margin-left: 26px;
`;

interface ActionButtonProps {
  children: React.ReactNode;
  isCentered?: boolean;
  isWhite?: boolean;
  bgColor?: string;
  isSmall?: boolean;
  [key: string]: any;
}

export const ActionButton = ({
  children,
  isCentered,
  isWhite,
  isSmall,
  bgColor = '#f8f9f8',
  ...rest
}: ActionButtonProps) => {
  const arrowMotion = {
    rest: {
      x: 0,
      transition: {
        duration: 0.3,
        type: 'tween',
        ease: 'easeIn',
      },
    },
    hover: {
      x: 8,
      transition: {
        duration: 0.3,
        type: 'tween',
        ease: 'easeOut',
      },
    },
  };

  return (
    <Wrapper isSmall={isSmall}>
      <Host
        isWhite={isWhite}
        isSmall={isSmall}
        initial="rest"
        whileHover="hover"
        whileTap="hover"
        animate="rest"
        bgColor={bgColor}
        {...rest}>
        <Content isWhite={isWhite} isSmall={isSmall}>
          <Text isCentered={isCentered}>{children}</Text>
          {!isCentered && (
            <Icon variants={arrowMotion}>
              <svg
                width="26"
                height="12"
                viewBox="0 0 26 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.5879 1L23.5879 6L18.5879 11"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <path
                  d="M23.5879 6H0.587891"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </svg>
            </Icon>
          )}
        </Content>
        <Bg />
      </Host>
    </Wrapper>
  );
};
