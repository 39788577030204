import { Modal } from '@mantine/core';
import React, { useState } from 'react';
import styled from 'styled-components';

const Host = styled.div`
  position: relative;
`;

const Iframe = styled.div``;

const PlayButton = styled.button`
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
  border: none;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: ${(props) => props.theme.colors.blue[4]};
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: ${(props) => props.theme.colors.blue[8]};
    border-radius: 50%;
    transition: all 200ms;
  }

  &:hover&:after {
    background-color: darken(#fa183d, 10%);
  }

  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }
`;

const PlayIcon = styled.span`
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
`;

interface VideoProps {
  children: React.ReactNode;
  iframe: React.ReactNode;
}

export const Video = ({ children, iframe }: VideoProps) => {
  const [opened, setOpened] = useState(false);
  return (
    <Host>
      <Iframe>
        <Modal
          opened={opened}
          centered
          onClose={() => setOpened(false)}
          size="xl"
          transition="fade"
          withCloseButton={false}
          transitionDuration={600}
          transitionTimingFunction="ease">
          {iframe}
        </Modal>
      </Iframe>
      {children}

      <PlayButton onClick={() => setOpened(true)} title="Spila myndband">
        <PlayIcon />
      </PlayButton>
    </Host>
  );
};
