import { motion, useInView } from 'framer-motion';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { device } from '../../styles/devices';

const Host = styled(motion.div)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;

  > div > div {
    margin: 0 20px 50px;
  }

  > div > article {
    margin: 3px 0;
  }

  @media ${device.tablet} {
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;

    > div > div {
      margin-bottom: 0;
    }

    > div > article {
      margin: 3px;
      flex: 50%;
    }
  }

  @media ${device.desktop} {
    flex-flow: row nowrap;
    > div > article {
      margin: 0 3px;
      flex: 1;
    }
  }
`;

const ChidItem = styled(motion.div)`
  > * {
    height: 100%;
  }
`;

interface FeaturesProps {
  children: React.ReactNode;
}

const Child = ({ i }: { i: any }) => {
  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };
  return <ChidItem variants={variants}>{i}</ChidItem>;
};

export const Features = ({ children }: FeaturesProps) => {
  const reffi = useRef(null);
  const isInView = useInView(reffi, { once: true });

  const variantsHere = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };
  return (
    <Host
      ref={reffi}
      initial="closed"
      variants={variantsHere}
      animate={isInView ? 'open' : 'closed'}>
      {React.Children.toArray(children).map((item, i) => (
        <Child i={item} key={i} />
      ))}
    </Host>
  );
};
