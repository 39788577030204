import { motion, useInView } from 'framer-motion';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { device } from '../../styles/devices';

const Host = styled(motion.div)`
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;

  @media ${device.tablet} {
    flex-flow: row nowrap;
  }

  > * {
    margin: 16px 0;

    @media ${device.mobile} {
      margin: 16px;
    }

    @media ${device.tablet} {
      margin: 8px;
    }

    @media ${device.desktop} {
      margin: 16px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ChidItem = styled(motion.div)`
  cursor: pointer;
  > * {
    height: 100%;
  }
`;

interface CardGroupProps {
  children: React.ReactNode;
}

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Child = ({ i }: { i: any }) => {
  return (
    <ChidItem
      variants={variants}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.98 }}>
      {i}
    </ChidItem>
  );
};

export const CardGroup = ({ children }: CardGroupProps) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const variantsHere = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };
  return (
    <Host
      ref={ref}
      initial="closed"
      variants={variantsHere}
      animate={isInView ? 'open' : 'closed'}>
      {React.Children.toArray(children).map((item, i) => (
        <Child i={item} key={i} />
      ))}
    </Host>
  );
};
