import React from 'react';
import styled from 'styled-components';
import { Segment } from '../segment/segment';

const Host = styled.div`
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    31deg,
    #04d0d4 -9.11%,
    #046ef3 42.29%,
    #054ffe 61.54%
  );

  color: #fff;

  > article {
    padding-bottom: 90px;
  }

  header {
    margin-bottom: 50px;
  }
`;

const Center = styled.div`
  text-align: center;
  z-index: 2;
`;

const Image = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
  }

  > div {
    z-index: 3;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    z-index: 1;
    display: block;
    max-width: 960px;
    width: 100%;
    aspect-ratio: 1/1;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #55d6f9 1%,
      rgba(171, 235, 252, 0.5) 48%,
      rgba(231, 249, 254, 0.14) 84%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

interface SignupProps {
  children: React.ReactNode;
  image: React.ReactNode;
  title: string;
  description: string | React.ReactNode;
}

export const Signup = ({ children, image, ...rest }: SignupProps) => {
  return (
    <Host>
      <Segment {...rest}>
        <Center>{children}</Center>
      </Segment>
      <Image>{image}</Image>
    </Host>
  );
};
