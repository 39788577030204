import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/devices';
import { bodyS, h6 } from '../../styles/typography';
import { LazyShow } from '../reveal/reveal';

const Content = styled.div`
  margin-bottom: ${(props) => props.theme.gutter}px;
  text-align: center;
  max-width: 650px;
  @media ${device.tablet} {
    margin-bottom: 0;
    padding: 0 40px;
  }
`;

const Host = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;

  @media ${device.tablet} {
    align-items: center;
  }

  &:after,
  &:before {
    content: '';
    display: inline-block;
    height: 50px;
    top: -70px;

    position: absolute;
    left: 50%;

    width: 2px;

    background: linear-gradient(
      to bottom,
      transparent 50%,
      rgba(0, 0, 0, 0.2) 50%
    );
    background-size: 1px 12px, 100% 1px;

    @media ${device.tablet} {
      height: 100px;
      top: -130px;
    }
  }

  &:before {
    background: linear-gradient(
      180deg,
      rgba(247, 249, 248, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 1;
  }
`;

const Kicker = styled.p`
  margin: 0;
  ${bodyS}
  font-weight: 600;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

const Title = styled.h3`
  margin: 0;
  ${h6};
  font-weight: 600;
  margin-bottom: 16px;
`;

const Copy = styled.p`
  ${bodyS}
  margin: 0;
  margin-bottom: 32px;
`;

const Action = styled.div`
  margin-top: ${(props) => props.theme.gutter}px;
`;

const Items = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  gap: 16px;
  > a {
    flex: 1;
    max-width: 60px;
    height: 60px;
  }
`;

interface StepItemProps {
  kicker?: string;
  title: string;
  copy: React.ReactNode;
  image?: React.ReactNode;
  action?: React.ReactNode;
  items?: React.ReactNode;
}

export const StepItem = ({
  kicker,
  title,
  copy,
  action,
  items,
}: StepItemProps) => {
  return (
    <LazyShow>
      <Host>
        <Content>
          <Kicker>{kicker}</Kicker>
          <Title>{title}</Title>
          <Copy>{copy}</Copy>
          {items && <Items>{items}</Items>}
          {action && <Action>{action}</Action>}
        </Content>
      </Host>
    </LazyShow>
  );
};
