import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const Host = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: -80px;
  background: rgba(0, 0, 0, 0.853256) 48.04%;
  background: ${({ theme }) =>
    theme.colorScheme === 'dark'
      ? theme.colors.gray[9]
      : `linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.853256) 0%,
    rgba(245, 247, 246, 0.858555) 10%,
    rgba(246, 248, 247, 0.77) 50%,
    rgba(245, 247, 246, 0.858555) 75%,
    rgba(255, 255, 255, 0.853256) 100%
  );`};
`;

const Blur = styled.div`
  position: absolute;
  inset: 0;
  backdrop-filter: blur(50px);
  z-index: 1;
`;

const HeroBg = styled.div`
  inset: 0;
  overflow: hidden;
  opacity: 0.2;
`;

const Blob1 = styled(motion.div)`
  @keyframes foo {
    0% {
      transform: translate(10vw, 10vh);
    }
    100% {
      transform: translate(80vw, 50vh);
    }
  }

  position: absolute;
  top: 0;
  left: 0;
  transform: translate(10vw, 10vh);
  /* animation: 15s ease-in-out 1s infinite alternate foo;
  animation-direction: alternate; */
`;

const Blob2 = styled(motion.div)`
  @keyframes bar {
    0% {
      transform: translate(60vw, 10vh);
    }
    100% {
      transform: translate(0vw, 40vh);
    }
  }
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(60vw, 10vh);
  /* animation: 25s ease-in-out 1s infinite alternate bar;
  animation-direction: alternate; */
`;

const Blob3 = styled(motion.div)`
  @keyframes barr {
    0% {
      transform: translate(0vw, 80vh);
    }
    100% {
      transform: translate(80vw, 40vh);
    }
  }
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0vw, 80vh);
  /* animation: 25s ease-in-out 1.2s infinite alternate barr;
  animation-direction: alternate; */
`;

const Childs = styled.div`
  position: relative;
  z-index: 2;
`;

interface BackgroundAnimationProps {
  children: React.ReactNode;
}

export const BackgroundAnimation = ({ children }: BackgroundAnimationProps) => {
  return (
    <Host>
      <Childs>{children}</Childs>
      <HeroBg>
        <Blob3
          animate={{
            opacity: [0, 1, 1],
            scale: [0.5, 1, 1],
            y: ['80vh', '40vh'],
            x: ['0vw', '80vw'],
          }}
          transition={{
            duration: 25,
            ease: 'easeInOut',
            times: [0, 0.1, 1],
            repeat: Infinity,
            repeatType: 'mirror',
          }}>
          <svg
            width="317"
            height="317"
            viewBox="0 0 317 317"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_92_1545)">
              <ellipse
                cx="158.681"
                cy="158.686"
                rx="125.238"
                ry="125.209"
                transform="rotate(56 158.681 158.686)"
                fill="#8DE4F6"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_92_1545"
                x="0.830814"
                y="0.824772"
                width="315.7"
                height="315.722"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="16.3097"
                  result="effect1_foregroundBlur_92_1545"
                />
              </filter>
            </defs>
          </svg>
        </Blob3>
        <Blob1
          animate={{
            opacity: [0, 1, 1],
            scale: [0.5, 1, 1],
            y: ['10vh', '50vh'],
            x: ['10vw', '80vw'],
          }}
          transition={{
            duration: 15,
            ease: 'easeInOut',
            times: [0, 0.1, 1],
            repeat: Infinity,
            repeatType: 'mirror',
          }}>
          <svg
            width="460"
            height="482"
            viewBox="0 0 460 482"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M226.614 327.046L155.624 287.15L156.018 196.45L228.177 155.336L304.209 202.207L294.117 287.097L226.614 327.046Z"
              stroke="#0055EE"
              strokeWidth="0.129012"
            />
            <ellipse
              cx="230.111"
              cy="242.16"
              rx="178.982"
              ry="179.154"
              transform="rotate(75.9548 230.111 242.16)"
              fill="url(#paint0_linear_285_483)"
            />
            <path
              opacity="0.1"
              d="M375.951 327.512L378.807 158.56L232.581 71.6648L83.4988 153.71L80.6426 322.661L226.869 409.556L375.951 327.512ZM138.839 290.494L140.561 188.613L230.462 139.146L318.652 191.561L316.929 293.442L227.017 342.931L138.839 290.494Z"
              fill="white"
            />
            <path
              opacity="0.2"
              d="M362.625 243.185L297.788 126.499L164.89 123.931L96.825 238.04L161.662 354.725L294.56 357.294L362.625 243.185ZM188.177 310.085L149.079 239.722L190.128 170.917L270.282 172.471L309.38 242.835L268.333 311.66L188.177 310.085Z"
              fill="white"
            />
            <path
              d="M324.259 187.316L230.739 131.285L134.976 184.887L132.727 294.517L226.248 350.548L322.011 296.946L324.259 187.316ZM227.047 307.707L170.653 273.919L172.014 207.812L229.773 175.487L286.168 209.275L284.816 275.396L227.047 307.707Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_285_483"
                x1="101.638"
                y1="385.805"
                x2="341.766"
                y2="117.841"
                gradientUnits="userSpaceOnUse">
                <stop offset="0.01" stopColor="#00D0D4" />
                <stop offset="0.02" stopColor="#00CCD5" />
                <stop offset="0.19" stopColor="#0097E7" />
                <stop offset="0.34" stopColor="#0070F4" />
                <stop offset="0.46" stopColor="#0059FC" />
                <stop offset="0.53" stopColor="#0050FF" />
                <stop offset="0.63" stopColor="#2D48F8" />
                <stop offset="0.89" stopColor="#9C35E7" />
                <stop offset="1" stopColor="#C92DE0" />
              </linearGradient>
            </defs>
          </svg>
        </Blob1>
        <Blob2
          animate={{
            opacity: [0, 1, 1],
            scale: [0.5, 1, 1],
            y: ['10vh', '40vh'],
            x: ['60vw', '0vw'],
          }}
          transition={{
            duration: 25,
            ease: 'easeInOut',
            times: [0, 0.1, 1],
            repeat: Infinity,
            repeatType: 'mirror',
          }}>
          <svg
            fill="none"
            height="1381"
            viewBox="0 0 1349 1381"
            width="1349"
            xmlns="http://www.w3.org/2000/svg">
            <linearGradient
              id="a"
              gradientUnits="userSpaceOnUse"
              x1="384.671"
              x2="-405.362"
              y1="-394.976"
              y2="406.449">
              <stop offset="0" stopColor="#ffae01" />
              <stop offset=".364128" stopColor="#fd00a3" />
              <stop offset=".73659" stopColor="#fd2a65" />
              <stop offset="1" stopColor="#fe6b04" />
            </linearGradient>
            <ellipse
              fill="url(#a)"
              rx="400.672"
              ry="403.024"
              transform="matrix(.698161 -.71594 .647069 .762431 674.323 690.152)"
            />
            <g fill="#fff">
              <path
                d="m683.259 293.585-318.798 186.497-11.421 399.115 307.356 212.633 318.798-186.5 11.421-399.114zm175.692 528.902-192.241 112.461-185.327-128.232 6.902-240.717 192.241-112.461 185.383 128.23z"
                opacity=".1"
              />
              <path
                d="m539.378 416.952-151.173 265.953 132.458 275.744 283.622 9.808 151.173-265.953-132.458-275.744zm305.242 281.946-91.16 160.375-171.024-5.929-79.88-166.315 91.161-160.375 171.061 5.904z"
                opacity=".2"
              />
              <path d="m474.557 559.321-8.158 255.778 199.113 135.557 207.271-120.204 8.158-255.778-199.114-135.557zm325.469 62.489-4.919 154.239-124.99 72.472-120.086-81.766 4.919-154.239 125.007-72.507z" />
            </g>
          </svg>
        </Blob2>
      </HeroBg>
      <Blur></Blur>
    </Host>
  );
};
