import React from 'react';
import styled from 'styled-components';
import { bodyS, h7 } from '../../styles/typography';

const Host = styled.article`
  position: relative;
  max-width: 350px;
  background: ${({ theme }) =>
    theme.colorScheme === 'dark' ? theme.colors.gray[8] : '#fff'};
`;

const Figure = styled.div``;

const Content = styled.div`
  padding: 36px;
`;

const Title = styled.h2`
  margin: 0;
  ${h7};
  font-weight: 600;
  margin-bottom: ${(props) => props.theme.gutter / 2}px;
`;

const Copy = styled.p`
  margin: 0;
  ${bodyS};
  margin-bottom: ${(props) => props.theme.gutter}px;
`;

const Action = styled.div`
  margin-bottom: ${(props) => props.theme.gutter / 2}px;
  a:after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

interface CardProps {
  image: React.ReactNode;
  title: string;
  copy: string;
  action: React.ReactNode;
}

export const Card = ({ image, title, copy, action }: CardProps) => {
  return (
    <Host>
      <Figure>{image}</Figure>
      <Content>
        <Title>{title}</Title>
        <Copy>{copy}</Copy>
        <Action>{action}</Action>
      </Content>
    </Host>
  );
};
